import React, { useState } from "react"
import styled from "styled-components"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link, graphql } from "gatsby"
import { Section, SectionPageTitle, BreadCrumb } from "../components/Section"
import CallNowSection from "../components/CallNow"
import { StaticImage } from "gatsby-plugin-image"
import ArticlesCarousel from "../components/ArticlesCarousel"
import ArticleCard from "../components/ArticleCard"
import BreakpointUp from "../components/Media/BreakpointUp"

const BackgroundImage = styled.div`
  height: 100%;
  width: 50%;
  position: absolute;
  top: 0px;
  right: 0;
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      @media (min-width: 1600px) {
        object-position: left center !important;
      }
    }
  }
`

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  ${BreakpointUp.lg`        
        margin:0 -25px;    
    `}
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`        
        padding:0 25px;         
        flex: 0 0 75%;
        max-width: 75%;
    `}
`
const SectionGridRight = styled.div`
  position: relative;
  width: 100%;
  padding: 0 15px;
  ${BreakpointUp.lg`        
        padding:0 25px;
        flex: 0 0 25%;
        max-width: 25%;
    `}
`
const SectionGridTitle = styled.h2`
  font-size: 24px;
  line-height: 34px;
  @media (min-width: 992px) {
    font-size: 28px;
    line-height: 38px;
  }
`
const SectionListGrid = styled.ul`
  margin: 0;
  padding: 0;
`
const SectionItemGrid = styled.li`
  margin: 0 0 30px;
  padding: 0;
`
const ListGroupFlush = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
`
const ListGroupItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0;
  border-bottom: 1px solid #dde4f0;
  &:first-child {
    padding-top: 0;
  }
  & div {
    color: #444;
    &:hover,
    &:focus {
      color: #0b619b;
      cursor: pointer;
    }
  }
`
const Badge = styled.span`
  min-width: 34px;
  min-height: 24px;
  background-color: #dde4f0;
  color: #444;
  font-weight: 700;
  font-size: 12px;
  display: inline-block;
  text-align: center;
  border-radius: 10px;
`

const Articles = ({ data, location }) => {
  const allArticles = data.allContentfulArticle.edges
  const [currentCategory, setCategory] = useState("All")
  const allCategories = data.allContentfulArticleCategory.edges

  let featuredArticles = []
  allArticles.forEach(function (item) {
    if (item.node.featuredArticle) {
      featuredArticles.push(item)
    }
  })

  const numberOfArticles = data => {
    var flag = 0
    allArticles.forEach(function (item) {
      if (item.node.blogCategories) {
        for (var i = 0; i < item.node.blogCategories.length; i++) {
          if (item.node.blogCategories[i].name === data) {
            flag = flag + 1
          }
        }
      }
    })
    return flag
  }

  const hasCurrentCategory = (data) => {
    console.log("Here is category evaluation")
    let flag = 0;
    for (var i = 0; i < data.node.blogCategories.length; i++) {
      if (data.node.blogCategories[i].name === currentCategory) {
        flag = flag + 1
      }
    }
    if(flag > 0) {
      return true
    } else {
      return false
    }
  }

  return (
    <Layout location={location}>
      <Seo title="Blog Articles" description="Blog Articles" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Blog</span>
        </div>
      </BreadCrumb>
      <Section pt="215px" pb="90px" xpt="120px" xpb="60px" bgColor="#FAFAFA">
        <BackgroundImage>
          <StaticImage
            src="../images/featured-articles.png"
            alt="featured-articles"
          />
        </BackgroundImage>
        <div className="container">
          <SectionPageTitle textAlign="left">
            Featured Articles
          </SectionPageTitle>
        </div>
        <ArticlesCarousel data={featuredArticles} />
      </Section>
      <Section pt="70px" pb="70px" xpt="60px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionGrid>
            <SectionGridLeft>
              <SectionGridTitle>Explore All Articles</SectionGridTitle>
              <>
                {currentCategory === "All" ? (
                  <SectionListGrid>
                    {allArticles.map(item => (
                      <SectionItemGrid key={v4()}>
                        <ArticleCard data={item.node} />
                      </SectionItemGrid>
                    ))}
                  </SectionListGrid>
                ):(
                  <SectionListGrid>
                    {allArticles.map(item => {
                      if(item.node.blogCategories?.length > 0 && hasCurrentCategory(item)) {
                        return(
                          <SectionItemGrid key={v4()}>
                            <ArticleCard data={item.node} />
                          </SectionItemGrid>
                        )
                      }
                    })}
                  </SectionListGrid>
                )}
              </>
            </SectionGridLeft>
            <SectionGridRight>
              <div className="isSticky">
                <SectionGridTitle>Categories</SectionGridTitle>
                <ListGroupFlush>
                  <ListGroupItem>
                    <div
                      onClick={() => setCategory("All")}
                      aria-hidden="true"
                    >
                      All
                    </div>
                    <Badge>{allArticles.length}</Badge>
                  </ListGroupItem>
                  {allCategories.map(item => {
                    return (
                      <ListGroupItem key={v4()}>
                        <div
                          onClick={() => setCategory(item.node.name)}
                          aria-hidden="true"
                        >
                          {item.node.name}
                        </div>
                        <Badge>{numberOfArticles(item.node.name)}</Badge>
                      </ListGroupItem>
                    )
                  })}
                </ListGroupFlush>
              </div>
            </SectionGridRight>
          </SectionGrid>
        </div>
      </Section>
      <CallNowSection />
    </Layout>
  )
}
export default Articles

export const pageQuery = graphql`
  query ArticlesPageQuery {
    allContentfulArticle(sort: { order: DESC, fields: postDate }) {
      edges {
        node {
          id
          url
          metaTitle
          metaDescription {
            metaDescription
          }
          title
          image {
            title
            gatsbyImageData
          }
          createdAt
          postDate(formatString: "DD MMM YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          featuredArticle
          blogCategories {
            name
          }
        }
      }
    }
    allContentfulArticleCategory {
      edges {
        node {
          name
        }
      }
    }
  }
`
