import React, { Component } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { v4 } from "uuid"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { ArrowLeftIcon, ArrowRightIcon } from "../../components/Icons"
import ArticleCard from "../../components/ArticleCard"
import BreakpointUp from "../../components/Media/BreakpointUp"

const SlickSlider = styled.div`
  padding: 0;
  max-width: 1920px;
  margin: 0 auto;
  width: 100%;
`
const SlickItem = styled.div`
  display: block !important;
  outline: none;
  max-width: 100%;
  padding: 0 60px;
  .card {
    margin: 0 -50px;
    background-color: #fff;
    .card-img {
      padding: 30px 30px 0;
      ${BreakpointUp.lg`
        padding: 30px;
      `}
      ${BreakpointUp.xl`
        padding: 50px;
      `}
      figure {
        height: 245px;
        @media (min-width: 768px) {
          height: 345px;
        }
        .gatsby-image-wrapper {
          height: 100%;
        }
      }
    }
    .card-title {
      font-size: 24px;
      line-height: 34px;
      @media (min-width: 768px) {
        font-size: 28px;
        line-height: 38px;
      }
      @media (min-width: 992px) {
        font-size: 32px;
        line-height: 42px;
      }
    }
    .card-body {
      padding: 30px;
      ${BreakpointUp.xl`          
        padding: 50px;
      `}
      p {
        
        ${BreakpointUp.lg`
          font-size:18px;
          line-height:30px;
        `}
        ${BreakpointUp.xl`
          margin-left:50px;
        `}
      }
      .btn {
        ${BreakpointUp.lg`
          font-size:18px;          
        `}
      }
    }
    .card-desc{
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;  
      overflow: hidden;
      margin-bottom:15px;
     
    }
  }
`
const SlickArrowTrack = styled.div`
  display: none;
  position: absolute;
  top: calc(50% - 45px);
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  right: 0;
  min-height: 30px;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`
const LeftArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  left: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: #fc0002;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`
const RightArrow = styled.div`
  position: absolute;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #fff;
  right: -18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    fill: #fc0002;
  }
  &:hover {
    background-color: #0b619b;
    svg {
      fill: #fff;
    }
  }
`

function CircleNextArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowRightIcon />
    </div>
  )
}

function CirclePrevArrow(props) {
  const { className, style, onClick } = props
  return (
    <div
      className={className}
      style={{ ...style, display: "flex" }}
      onClick={onClick}
      aria-hidden="true"
    >
      <ArrowLeftIcon />
    </div>
  )
}

export default class ArticlesCarousel extends Component {
  constructor(props) {
    super(props)
    this.next = this.next.bind(this)
    this.previous = this.previous.bind(this)
  }
  next() {
    this.slider.slickNext()
  }
  previous() {
    this.slider.slickPrev()
  }
  render() {
    const { data } = this.props
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
      nextArrow: <CircleNextArrow />,
      prevArrow: <CirclePrevArrow />,
      arrow: false,
      centerMode: true,
      centerPadding: "195px",
      focusOnSelect: true,
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            centerPadding: "100px",
          },
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: "30px",
          },
        },
        {
          breakpoint: 567,
          settings: {
            centerPadding: "0",
          },
        },
      ],
    }
    return (
      <SlickSlider>
        <Slider {...settings} ref={c => (this.slider = c)}>
          {data.map(item => (
            <SlickItem key={v4()}>
              <ArticleCard data={item.node} />
            </SlickItem>
          ))}
        </Slider>
        <SlickArrowTrack>
          <LeftArrow onClick={this.previous}>
            <ArrowLeftIcon />
          </LeftArrow>
          <RightArrow onClick={this.next}>
            <ArrowRightIcon />
          </RightArrow>
        </SlickArrowTrack>
      </SlickSlider>
    )
  }
}
